<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 631.94 514.83"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M348.84,41.83c-2.95,2-7.9,3.5-10.86,2.39-19.46-7.32-37.71-2.12-53.62,7.72C260.25,66.86,236.14,82.59,215,101.35c-27.25,24.23-45.54,55.22-51.16,92.13-3.34,22-.38,42.59,17.37,58.52,22.91,20.56,54.4,21,83,.52,31.67-22.63,54.05-52.7,69-88.48,2.67-6.4,5.49-12.74,8.24-19.1l3.94.95c-.75,12.57-1.4,25.15-2.26,37.71-4.82,70.25-17.25,138.41-61,196.3-38.44,50.89-86.31,88-152.31,95.41-27.64,3.11-52.65-2.49-73.13-22.74-4-4-8.13-9.51-13.09-10.73-10-2.48-21.1-4.77-31-2.95C1.1,441,1,452.42.15,462.76c-1.69,19.91,11.09,29.18,25.6,36.81,32.8,17.23,68.78,17.31,103.61,12.62C227.47,499,292.64,440.73,333.67,353.6,366.1,284.72,375.42,210.83,377,135.46c.36-17.34,5.25-34.54,6.93-51.91,1-10.52,2.33-22-.82-31.72C377.56,34.81,363.73,32,348.84,41.83Zm-19.39,56C321.5,150.87,292.38,191.46,249.5,222c-6.76,4.81-20.5,4.79-28.56,1.32-7-3-10.48-14.09-16.13-22.51,7.58-18.36,12.79-34.65,20.74-49.47a214.33,214.33,0,0,1,69-76.46c6.77-4.61,20.63-6.47,26.55-2.55S330.69,89.52,329.45,97.81Z"></path><path d="M606.77,189.26a391.87,391.87,0,0,1-2.64-70.84c1.58-29.46,7.3-58.69,8.9-88.14.43-7.9-5.66-20.71-12.17-23.62C576-4.46,551.91-2.66,532.3,19.75c-8.13,9.29-18.12,17-26.11,26.39-33.82,39.8-64.59,81.47-74.86,134.4-5.48,28.24,1,48.29,18.34,58.18,17.63,10.07,38.32,5.83,59.81-12.54a50.49,50.49,0,0,0,9.38-10.32C534.75,191.8,550.4,167.59,566,143.6c2.45,18.68,3.67,37.88,7.71,56.46,5.63,25.91,24.72,37.05,50.36,29.71l7.85-31.38C620.56,210.38,608.92,205.85,606.77,189.26ZM572.27,77.51c-23.54,40.61-49.78,79.69-75.74,118.85-3,4.51-10.9,5.74-16.54,8.5-2.49-6.47-8.06-13.53-6.92-19.27,3.58-18.15,8.07-36.45,15.13-53.49C502.4,97.81,522.41,67,550.45,42c6.79-6,14.45-11.09,23.07-17.62C586.5,44.75,580.62,63.12,572.27,77.51Z"></path></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconGiftAid',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
